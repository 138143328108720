import React from 'react'
import Feed from './Feed'
import Sidebar from './Sidebar'
import Widgets from './Widgets.js'
import './App.css'

function App() {
  return (
    // BEM
    <div className='app'>
      <Sidebar />
      <Feed />
      <Widgets />
    </div>
  )
}

export default App
