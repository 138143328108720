import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyBG-sc5iNKlv-NAAedEqBd49LOGgrTdiaY',
  authDomain: 'lifetime-bf2ee.firebaseapp.com',
  projectId: 'lifetime-bf2ee',
  storageBucket: 'lifetime-bf2ee.appspot.com',
  messagingSenderId: '695202815316',
  appId: '1:695202815316:web:95a2ad85d619c27f833cc1',
  measurementId: 'G-1YPL42N0KP',
}

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()

export default db
